import * as React from 'react';

import {
  Goback,
  Icon,
  NotFoundContent,
  NotFoundImage,
  NotFoundWrapper,
} from './style';
import { Link, graphql, useStaticQuery } from 'gatsby';

import GatsbyImage from '../../components/gatsby-image';
import { IoMdArrowRoundBack } from 'react-icons/io';

interface NotFoundProps {}

const ThankYou: React.FunctionComponent<NotFoundProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/success.png/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `);

  return (
    <NotFoundWrapper>
      <NotFoundContent>
        <h1>Thank you for your message</h1>
        <p>
          We will get back to you within 2-3 business days.
        </p>
        <Goback>
          <Link to="/">
            <Icon>
              <IoMdArrowRoundBack />
            </Icon>
            Go Back Home
          </Link>
        </Goback>
      </NotFoundContent>
      <NotFoundImage>
        <GatsbyImage src={Data.avatar.childImageSharp.gatsbyImageData} alt="Thank you for contacting us" />
      </NotFoundImage>
    </NotFoundWrapper>
  );
};

export default ThankYou;
