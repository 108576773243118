import Layout from '../containers/layout';
import NotFound from '../containers/not-found';
import React from 'react';
import ResetCss from '../components/reset-css';
import SEO from '../components/seo';
import ThankYou from '../containers/thank-you';
import { graphql } from 'gatsby';

const NotFoundPage = () => {
  return (
    <>
      <Layout>
        <ResetCss />
        <SEO title="Success: Thank you for contacting us" />
        <ThankYou />
      </Layout>
    </>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
